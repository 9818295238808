import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import HasAccess from '../../components/HasAccess/HasAccess';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';
import SearchField  from '../../utils/searchField';

import reducer, { actions as LocationsAction, types as locationsTypes, makeSelectBuildingsList } from '../../reducers/locations';
import saga from '../../sagas/locations';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as AppActions } from '../../reducers/app';
import { selectUserCompany, selectDataSyncEnabled } from '../../reducers/auth';

import { createStructuredSelector } from 'reselect';

import BuildingsTable from '../../containers/BuildingsTable/BuildingsTable';
import LocationsTable from '../../containers/LocationsTable/LocationsTable';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { debounce } from 'lodash';
import './LocationsPage.css';

const initialSortColumns = [
    {
        id: 'name',
        desc:false
    }
];

function Table(props) {
    var radioLocationSession = sessionStorage.getItem("radioLocationSession");

    if ((props.radioLocation === "locations" || radioLocationSession === "locations" || props.company.locationTreeType === "SIMPLE")) {
        return <LocationsTable {...props} simple={props.company.locationTreeType === "SIMPLE"} />  
    }
    else {
        return <BuildingsTable {...props}/>
    }
}

function LocationsPage(props) {
    const [searchValue, setSearchValue] = useState('');
    const [radioLocation, setRadioLocation] = useState(sessionStorage.getItem("radioLocationSession") === "locations" ? 'locations' : 'buildings');
    const [isSearchData, setIsSearchData] = useState(false);
    const [expandedList, setExpandedList] = useState([]);
    const [expandBuildingsToggle, setExpandBuildingsToggle] = useState(true);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [tabsValue, setTabsValue] = useState(setInitialTabsState(props));
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    const [nestedTableSortColumns, setNestedTableSortColumns] = useState(initialSortColumns);
    const [nestedTableCurrentSortColumn, setNestedTableCurrentSortColumn] = useState(initialSortColumns[0]);

    const isSimple = props.company.toJS().locationTreeType === "SIMPLE";

    useEffect(() => {
        props.setPageTitle('E911 Locations');
        props.setPageTitleId('locations-header');

        return () => {
            // clean up global state
            props.setSearchTerm('');
        }
    }, []);

    function setInitialTabsState(props) {
        if(props.dataSyncEnabled) {
            return 'MANAGER_DATA_SYNC';
        }
        else {
            return 'E911_ANYWHERE';
        }
    }
    
    function handleInputSearchChange(e) {
        const inputValue = e.target.value;
        const inputValue1 = inputValue.replace ( /[^A-Za-z0-9.,?@&;:!+#'~* /_-]/g, '' );

            setSearchValue(inputValue1);
            searchApiCall(
                inputValue1, 
                pageSize, 
                tabsValue === 'MANAGER_DATA_SYNC' ? 'MANAGER_DATA_SYNC' : 'default', 
                radioLocation, 
                { currentSortColumn, nestedTableCurrentSortColumn }
            );
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, tabsValue, radioLocation, sortData) => {
            props.setSearchTerm(inputValue);
            setPage(0);
            if (isSimple || (radioLocation === "locations")) {
                props.getLocationsRequest(props.company.toJS().id,  tabsValue, 1, pageSize, inputValue, sortData.nestedTableCurrentSortColumn.id, sortData.nestedTableCurrentSortColumn.desc ? 'DESC' : 'ASC');
            }
            else {
                props.fetchBuildings(1, pageSize, inputValue, sortData.currentSortColumn.id, sortData.currentSortColumn.desc ? 'DESC' : 'ASC', tabsValue);
            }
            if (inputValue.length > 0) {    
                setIsSearchData(true);
            } else if(inputValue.length === 0) {
                setIsSearchData(false);
            }
        }, 500)
    , []);

    function modalHandleOpen() {
        if (isSimple) {
             props.setCurrentModal(locationsTypes.ADD_LOCATION, { simple: true, companyId: props.company.toJS().id });
        } else {
           props.setCurrentModal(locationsTypes.ADD_BUILDING);
        }
    }

    function handleTabChange (event, value) {
        if(tabsValue !== value) {
            props.clearLocationsState();
            setSearchValue('');
            setExpandedList([]);
            setIsSearchData(false);
            setExpandBuildingsToggle(true);
            setTabsValue(value); 
        }
    }
    
    function handleRadioInput(e) {
        const inputValue = e.target.value
        setRadioLocation(inputValue)
        setSearchValue('');
        setPage(0);
        setPageSize(25);
        setIsSearchData(false);
        sessionStorage.setItem("radioLocationSession", inputValue);
        if (radioLocation === "buildings"){
            setExpandBuildingsToggle(true);
        }
    }
    
    return (
        <div>
            { (!isSimple) &&
                <Grid item style={{ marginRight: 'auto', marginLeft: '0px', position: 'relative' }}>
                    <FormLabel id="dm" htmlFor="idType" style={{padding: '0 10px 0 0'}}>Display Mode:</FormLabel>
                    <RadioGroup aria-labelledby="dm" id="idType" value={radioLocation} onChange={handleRadioInput} row style={{ alignItems: 'baseline' }}>
                        <FormControlLabel style={{ marginRight: 50 }} value={'buildings'} control={<Radio color="primary" 
                            inputProps={{
                                "aria-describedby":"buildings-tooltip"
                            }}
                        />} label="Buildings" />
                        <FormControlLabel value={'locations'} control={<Radio color="primary" 
                            inputProps={{
                                "aria-describedby":"locations-tooltip"
                            }}
                        />} label="Locations"/>
                    </RadioGroup>
                    <Tooltip title="Displays buildings and their locations in an expandable list. Searchable fields: Building Name, Address (of Building).">
                        <span id="buildings-tooltip" style={{ position: 'absolute', top: 32, left: 106}}><Info /></span>
                    </Tooltip>
                    <Tooltip title="Displays a list of locations. Searchable fields: Location Name, Location Information, Phone Number, Address (of related Building).">
                        <span id="locations-tooltip" style={{ position: 'absolute', top: 32, left: 260}}><Info /></span>
                    </Tooltip>
                </Grid>
            }
            {(props.dataSyncEnabled) &&
                <div>
                    <AppBar className="locations-app-bar" position="relative" color="default">
                        <Tabs className="tabs-bar" value={tabsValue} onChange={handleTabChange} indicatorColor="primary" variant="fullWidth">
                            { props.dataSyncEnabled && 
                                <Tab className="tabs" id="dataSync-header" value='MANAGER_DATA_SYNC' label="E911 MANAGER&reg; LOCATIONS" icon={
                                    <Tooltip title="Click on this tab to view locations managed via E911 Manager&reg;">
                                        <span id="dataSync-info"><Info /></span>
                                    </Tooltip>
                                }/>
                            }
                            <Tab className="tabs" id="e911-header" value='E911_ANYWHERE' label="E911 ANYWHERE&reg; LOCATIONS" icon={
                                <Tooltip title="Click on this tab to manage locations via E911 Anywhere®.">
                                    <span id="e911-info"><Info /></span>
                                </Tooltip>
                            }/>
                        </Tabs>
                    </AppBar>
                    <br/>
                    <br/>
                </div>
            }
            <Grid container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item style={{ marginRight: 'auto', marginLeft: '0px'}}>
                    <SearchField 
                        value={searchValue}
                        onChange={handleInputSearchChange}
                    />
                </Grid>
                { tabsValue === 'E911_ANYWHERE' && 
                    <Grid item>
                        <HasAccess
                            permissions={["ADD_LOCATIONS"]}
                        >
                            { ((!isSearchData && radioLocation === "buildings") || (!isSearchData && isSimple)) && 
                                <Button className="button" id="add-building-button" variant="contained" color="primary" onClick={modalHandleOpen}>
                                    { props.company.toJS().locationTreeType === "SIMPLE" ? "Add Location" : "Add Building" } 
                                </Button> 
                            }
                        </HasAccess>
                    </Grid>
                }
            </Grid>
            <br/>
            <Table  
                    company={props.company.toJS()}
                    setCurrentModal={props.setCurrentModal} 
                    searchTerm={searchValue}
                    fetchBuildings={props.fetchBuildings}
                    showPaginationBottom={true}
                    isSearchData={isSearchData}
                    setExpandedList={setExpandedList}
                    expandedList={expandedList} 
                    expandBuildingsToggle={expandBuildingsToggle} 
                    setExpandBuildingsToggle={setExpandBuildingsToggle}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    page={page}
                    setPage={setPage} 
                    searchValue={searchValue}
                    radioLocation={radioLocation}
                    sortColumns={sortColumns}
                    setSortColumns={setSortColumns}
                    currentSortColumn={currentSortColumn}
                    setCurrentSortColumn={setCurrentSortColumn}
                    nestedTableSortColumns={nestedTableSortColumns}
                    setNestedTableSortColumns={setNestedTableSortColumns}
                    nestedTableCurrentSortColumn={nestedTableCurrentSortColumn}
                    setNestedTableCurrentSortColumn={setNestedTableCurrentSortColumn}
                    tabsValue={tabsValue} />
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    buildings: makeSelectBuildingsList(),
    company: selectUserCompany(),
    dataSyncEnabled: selectDataSyncEnabled(),
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchBuildings: (page, pageSize, searchTerm, sortBy, direction, origin) => dispatch(LocationsAction.getBuildingsRequest(page, pageSize, searchTerm, sortBy, direction, origin)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setSearchTerm: searchTerm => dispatch(AppActions.setSearchTerm(searchTerm)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        getLocationsRequest: (organizationUnitId, origin, page, pageSize, searchTerm, sortBy, direction) => dispatch(LocationsAction.getLocationsRequest(organizationUnitId, origin, page, pageSize, searchTerm, sortBy, direction)),
        clearLocationsState: () => dispatch(LocationsAction.clearLocationsState())
    }
};

const withReducer = injectReducer({ key: 'locations', reducer });
const withSaga = injectSaga({ key: 'locations', saga });
const withConnect = connect(mapStateToProps,mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(LocationsPage);