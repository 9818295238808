import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import { Input, InputLabel, MenuItem, Tooltip } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Info from '@material-ui/icons/Info';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NumberFormat from 'react-number-format';
import ModalErrorMessage from '../../../../../components/ModalWrapper/ModalErrorMessage';
import AlertsSubscriptionsRecipientsTable from '../../../../../containers/AlertsSubscriptionsRecipientListTable/AlertsSubscriptionsRecipientsTable';
import TimeZone from '../../../../../components/TimeZone/TimeZone';
import AlertsAPI from '../../../../../apis/alertsApi';
import BuildingsAPI from '../../../../../apis/buildingsApi';
import LocationsAPI from '../../../../../apis/locationsApi';
import NotificationIntegrationsAPI from '../../../../../apis/notificationIntegrationsApi';
import AlertSubscriptionAsyncSelect from './AlertSubscriptionAsyncSelect';
import setTemplateType from '../util/SetTemplateType';
import BuildingOrLocationMultiComponent from '../../../../../components/BuildingOrLocationMultiComponent/BuildingOrLocationMultiComponent';
import './AlertsSubscriptions.css';

const AlertSubscriptionsForm = props => {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        setFieldValue,
        setFieldTouched,
        informacastList,
        userList,
        smsList,
        emailList,
        setUserList,
        setSmsList,
        setEmailList,
        setInformacastList,
        alertType,
        companyId,
        informacastEnabled,
        modalData,
        setTab,
        currentTab,
        completeList,
        setFormValues,
        classes } = props;

    const [informacast, setInformacast] = useState([]);
    const [alertTypes, setAlertTypes] = useState([]);
    const [alertTemplates, setAlertTemplates] = useState([]);
    const [templatesLoaded, setTemplatesLoaded] = useState(false);
    const [listData, setListData] = useState([]);

    const TABS_CONST = {
        USER: 'EON Alerts',
        SMS: 'SMS Alerts',
        EMAIL: 'Email Alerts',
        INFORMACAST: 'Informacast Alerts'
    }

    useEffect(() => {
        setFormValues(values);
    }, [values]);

    useEffect(() => {
        const form = document.querySelector('form');
        form.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                return false;
            }
        });
    }, []);

    useEffect(() => {
        if (informacastEnabled) {
            NotificationIntegrationsAPI.getNotificationIntegrationsList(companyId).then(response => {
                const filteredData = response.data.filter(item => {
                    const hasUnknownDistributionList = item.distributionLists.some(distributionList => distributionList.name === "[Unknown Distribution List]");
                    const templateName = item.templateName;
                    return !(hasUnknownDistributionList || templateName === "[Unknown Template]");
                });

                setInformacast(filteredData);
            }).catch(error => {
                console.log(error);
            })
        }
    }, []);

    useEffect(() => {
        AlertsAPI.getAlertTypes(companyId ? 'ORGANIZATION' : 'SYSTEM').then(
            response => {
                // response from api is Java Map<String,String> -> JS Object of Keys
                let alertTypeList = [];

                for (var key in response.data) {
                    if (key === 'MYE911_USER_WELCOME_EMAIL' || key === 'ORG_ADMIN_WELCOME_EMAIL') {
                        continue;
                    }

                    const value = response.data[key];

                    const alertTypeObject = { key, value };

                    if (key === values.alertType.name) {
                        setFieldValue('alertType', alertTypeObject)
                    }

                    alertTypeList.push(alertTypeObject);
                }

                setAlertTypes(alertTypeList);
            }
        )
    }, []);

    useEffect(() => {
        if (modalData) {
            AlertsAPI.getAlertsTemplatesList(props.companyId, alertType.name, 1 , 25).then(
                response => {
                    setFieldValue('template', response.data.find(alertTemplate => alertTemplate.id === values.template.id
                    ));
                    setAlertTemplates(setTemplateType(response.data))
                }
            )
        }
    }, []);

    useEffect(() => {
        if (modalData && modalData.hasFilters) {
            let firstItem = modalData.filterData[0];

            if (firstItem) {
                if (firstItem.filterType === 'BUILDING') {
                    setFieldValue('locationType', 'E911_LOCATIONS');
                }
                else {
                    LocationsAPI.getLocationById(firstItem.id).then((result) => {
                        // get origin, set initial
                        if (result.data.origin === 'default') {
                            setFieldValue('locationType', 'E911_LOCATIONS');
                        }
                        else
                            setFieldValue('locationType', 'CER_LOCATIONS');
                    });
                }   
            }
        }
    }, [props.modalData]);

    const handleOnChange = (values) => {
        if (values.value) {
            setFieldValue('sms', values.value);
        } else {
            setFieldValue('sms', '');
        }
        setFieldTouched('sms', true);
    }

    const handleEmailChange = (values) => {
        if (values.target.value) {
            setFieldValue('email', values.target.value);
        } else {
            setFieldValue('email', '');
        }
        setFieldTouched('email', true);
    }

    const onSelectUser = event => {
        const selectedUser = event;
        setFieldValue('user', selectedUser);
        props.setModalConfirm(true);
    };

    const onSelectInformacast = event => {
        const selectedInformacast = event.target.value;
        setFieldValue('informacast', selectedInformacast);
        props.setModalConfirm(true);
    };

    const handleUser = () => {
        if (values.user && !userList.find(item => item.user === values.user.email)) {
            setUserList([
                ...userList,
                {
                    userProfileId: values.user.id,
                    user: values.user.email,
                    listType: 'USER_LIST'
                }
            ]);
        }
    }

    const handleInformacast = () => {
        if (values.informacast.name && !informacastList.find(item => item.name === values.informacast.name)) {
            setInformacastList([
                ...informacastList,
                {
                    id: values.informacast.id,
                    name: values.informacast.name,
                    listType: 'INFORMACAST_LIST'
                }
            ]);
        }
    }

    const handleSms = () => {
        if (values.sms.length >= 10 && !smsList.find(item => item.phoneNumber.replace(/[() -]/g, '') === values.sms)) {
            setSmsList([
                ...smsList,
                {
                    phoneNumber: values.sms,
                    listType: 'SMS_LIST'
                }
            ]);
        }
    }

    const handleEmail = () => {
        if (values.email && (!errors.email || errors.email.includes('At least one recipient must be added to the list.')) && !emailList.find(item => item.email === values.email)) {
            setEmailList([
                ...emailList,
                {
                    email: values.email,
                    listType: 'EMAIL_LIST'
                }
            ]);
        }
    }

    const userListDeleteHandler = row => {
        setUserList(
            userList.filter(item => item.user !== row.original.user)
        );
    }

    const informacastListDeleteHandler = row => {
        setInformacastList(
            informacastList.filter(item => item.name !== row.original.name)
        );
    }

    const smsListDeleteHandler = row => {
        setSmsList(
            smsList.filter(item => item.phoneNumber !== row.original.phoneNumber)
        );
    }

    const emailListDeleteHandler = row => {
        setEmailList(
            emailList.filter(item => item.email !== row.original.email)
        );
    }

    const fieldTouched = () => {
        props.setModalConfirm(true);
    }

    function handleTabChange(event, value) {
        setTab(value);
    };

    const onSelectAlertType = (event) => {
        const selectedAlertType = event.target.value;
        setTemplatesLoaded(false);

        AlertsAPI.getAlertsTemplatesList(props.companyId, selectedAlertType.key, 1, 25).then(
            (response) => {
                setTemplatesLoaded(true);
                setAlertTemplates(response.data);
            }
        );
        setFieldValue('alertType', selectedAlertType);
        setFieldValue('template', '');

        if (selectedAlertType.key !== 'EMERGENCY_CALL_RECEIVED' || selectedAlertType.key !== 'ECRC_EMERGENCY_CALL_RECEIVED') {
            setInformacastList([]);
        }

        if (selectedAlertType.key === 'UNDEFINED_NETWORK_LOCATION') {
            setTab(1);
            setUserList([]);
        }
        if (selectedAlertType.key === 'REDSKY_933_TEST_CALL_RECEIVED') {
            setTab(1);
        }
    }

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }
    if (modalData) {
        modalData.filterData && modalData.filterData.length > 0 && modalData.filterData.forEach(item => {
            item.name = item.ouName;
            item.id = item.ouId;
        });
    }

    useEffect(() => {
        setFieldValue('sms', '');
        setFieldValue('email', '');
        setFieldValue('user', '');
        setFieldValue('informacast', '');
    }, [completeList]);

    return (
        <form onSubmit={handleSubmit} autoComplete="off" className="subscription-form" noValidate="novalidate">
            {modalLoading && <LinearProgress />}
            <TextField
                id="name"
                label="*Name"
                name="name"
                type="name"
                onChange={handleChange}
                value={values.name}
                onBlur={(e) => {
                    fieldTouched();
                    handleBlur(e);
                }}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))} />
            <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
            <br />
            <br />
            <InputLabel 
                shrink={true} 
                htmlFor="alertType" 
                error={touched.alertType && Boolean(errors.alertType)}
            >*Alert Type</InputLabel>
            <Select
                id="alertType"
                name="alertType"
                value={values.alertType}
                onChange={onSelectAlertType}
                onBlur={(e) => {
                    fieldTouched();
                    handleBlur(e);
                }}
                disabled={!!modalData}
                input={<Input name="alert" />}
                fullWidth={true}
                error={touched.alertType && Boolean(errors.alertType)}>
                {
                    alertTypes.map((alertType, index) =>
                        <MenuItem key={index} value={alertType}>{alertType.value}</MenuItem>)
                }
            </Select>
            <span id="alertType-helper-text" className="error-prompt">{touched.alertType ? errors.alertType && errors.alertType.value : ""}</span>
            <br />
            <InputLabel shrink={true} htmlFor="template" error={touched.template && Boolean(errors.template)}>*Template</InputLabel>
            <Select
                id="template"
                name="template"
                value={values.template}
                onChange={handleChange}
                onBlur={(e) => {
                    fieldTouched();
                    handleBlur(e);
                }}
                disabled={modalLoading || !templatesLoaded}
                input={<Input name="template" />}
                fullWidth={true}
                error={touched.template && Boolean(errors.template)}>
                {
                    alertTemplates.map((alertTemplate, index) =>
                        <MenuItem key={index} value={alertTemplate}>{alertTemplate.name}</MenuItem>)
                }
            </Select>
            {
                (templatesLoaded && alertTemplates.length === 0) &&
                <ModalErrorMessage
                    modalErrorMessage="No templates available for the currently selected alert type. Please add a template to this alert type." />
            }
            <span id="template-helper-text" className="error-prompt">{touched.template ? errors.template && errors.template.name : ""}</span>
            <br />
            <TimeZone
                value={values.timeZoneType}
                onChange={handleChange}
                disabled={modalLoading}
                touched={touched}
                errors={errors}
                fieldRequired={true}
                displayCurrentTimeZone={false}
            />
            <span id="timeZoneType-helper-text" className="error-prompt">{touched.timeZoneType ? errors.timeZoneType : ""}</span>
            <br/>
            {values.alertType.key === 'EMERGENCY_CALL_RECEIVED' || values.alertType.key === 'REDSKY_933_TEST_CALL_RECEIVED' ? 
            <BuildingOrLocationMultiComponent 
                {...props}
            />
            : ''
            }
            <br />
            <div className="section-wrapper">
                <h2 className={classes.subtitle}>
                    Recipients
                    <Tooltip
                        title="Add the Users, SMS numbers, and/or emails to where this alert will be delivered."
                        classes={{
                            tooltip: classes.tooltip
                        }}
                        style={{ marginLeft: '30px' }}
                    >
                        <span className={classes.icon} tabIndex={0}><Info /></span>
                    </Tooltip>
                </h2>
                <AppBar position="static" color="default">
                    <Tabs className="tabs-bar"
                        value={currentTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                    >
                        {values.alertType.key === 'UNDEFINED_NETWORK_LOCATION' || !companyId ? '' :
                            <Tab id="add-subscription-user-tab" value={0} key={0} label={TABS_CONST.USER} />
                        }
                        <Tab id="add-subscription-sms-tab" value={1} key={1} label={TABS_CONST.SMS} />
                        <Tab id="add-subscription-email-tab" value={2} key={2} label={TABS_CONST.EMAIL} />
                        {!informacastEnabled ? '' :
                            values.alertType.key === 'EMERGENCY_CALL_RECEIVED' ||
                                values.alertType.key === 'ECRC_EMERGENCY_CALL_RECEIVED' ?
                                <Tab id="add-subscription-informacast-tab" value={3} key={3} label={TABS_CONST.INFORMACAST} />
                                : ''
                        }
                    </Tabs>
                </AppBar>
                <br />
                {currentTab === 0 &&
                    <div>
                        <div className={classes.section}>
                            <InputLabel error={completeList.length <= 0 && (touched.user && Boolean(errors.user))} shrink={true} htmlFor="user">{currentTab === 0 && completeList.length <= 0 ? '*EON User' : 'EON User'}</InputLabel>
                            <AlertSubscriptionAsyncSelect
                                id="user"
                                isMulti={false}
                                defaultValue={values.user}
                                error={completeList.length <= 0 && (touched.user && Boolean(errors.user))}
                                onChange={onSelectUser}
                                isDisabled={modalLoading}
                                {...props}
                            />
                            {completeList.length <= 0 &&
                                <span id="user-helper-text" className="error-prompt">{touched.user ? errors.user : ""}</span>
                            }
                        </div>
                        <div style={{ flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <Button disabled={modalLoading}
                                onClick={handleUser}
                                className="button"
                                color="primary"
                                variant="contained">Add</Button>
                        </div>
                        <br />
                    </div>
                }
                {currentTab === 1 &&
                    <div>
                        <div className={classes.section}>
                            <NumberFormat
                                id="sms"
                                label={currentTab === 1 && completeList.length <= 0 ? '*Phone Number' : 'Phone Number'}
                                name="sms"
                                type="tel"
                                onValueChange={handleOnChange}
                                value={values.sms}
                                onBlur={(e) => {
                                    fieldTouched();
                                    handleBlur(e);
                                }}
                                disabled={modalLoading}
                                className={classes.field}
                                fullWidth={true}
                                error={touched.sms && Boolean(errors.sms)}
                                format="(###) ###-####"
                                customInput={TextField}
                                mask="_" />
                            <span id="sms-helper-text" className="error-prompt">{touched.sms ? errors.sms : ""}</span>
                        </div>
                        <Button disabled={modalLoading}
                            onClick={handleSms}
                            className="button"
                            color="primary"
                            variant="contained">Add</Button>
                        <br />
                    </div>
                }
                {currentTab === 2 &&
                    <div>
                        <div className={classes.section}>
                            <TextField
                                id="email"
                                label={currentTab === 2 && completeList.length <= 0 ? '*Email' : 'Email'}
                                type="email"
                                onChange={handleEmailChange}
                                value={values.email}
                                onBlur={(e) => {
                                    fieldTouched();
                                    handleBlur(e);
                                }}
                                disabled={modalLoading}
                                fullWidth={true}
                                error={(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max')))} />
                            <span id="email-helper-text" className="error-prompt">{(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max'))) ? errors.email : ''}</span>
                        </div>
                        <Button disabled={modalLoading}
                            className="button"
                            color="primary"
                            onClick={handleEmail}
                            variant="contained">Add</Button>
                        <br />
                    </div>
                }
                {currentTab === 3 &&
                    <div>
                        <div className={classes.section}>
                            <InputLabel error={touched.informacast && Boolean(errors.informacast)} shrink={true} htmlFor="informacast">{currentTab === 3 && completeList.length <= 0 ? '*InformaCast 911 Alert Entity' : 'InformaCast 911 Alert Entity'}</InputLabel>
                            <Select
                                id="informacast"
                                name="informacast"
                                value={values.informacast}
                                onChange={onSelectInformacast}
                                onBlur={(e) => {
                                    fieldTouched();
                                    handleBlur(e);
                                }}
                                disabled={modalLoading}
                                input={<Input id="informacast-label" />}
                                fullWidth={true}
                                error={touched.informacast && Boolean(errors.informacast)}>
                                {
                                    (informacast.length > 0) ? informacast.map((informacastItem, index) => (
                                        <MenuItem key={index} value={informacastItem}><em>{informacastItem.name}</em></MenuItem>
                                    )) : 
                                    <MenuItem disabled={true} value={null}>No options</MenuItem>
                                }
                            </Select>
                            <span id="informacast-helper-text" className="error-prompt">{touched.informacast ? errors.informacast : ""}</span>
                        </div>
                        <Button id="subscription-add-informacast-button"
                            disabled={modalLoading}
                            onClick={handleInformacast}
                            className="button"
                            color="primary"
                            variant="contained">Add</Button>
                        <br />
                    </div>
                }
                <AlertsSubscriptionsRecipientsTable
                    userList={userList}
                    informacastList={informacastList}
                    smsList={smsList}
                    emailList={emailList}
                    userListDeleteHandler={userListDeleteHandler}
                    informacastListDeleteHandler={informacastListDeleteHandler}
                    smsListDeleteHandler={smsListDeleteHandler}
                    emailListDeleteHandler={emailListDeleteHandler} />
            </div>
            <Grid className="fixed-button" container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                    type="submit"
                    onClick={handleScroll}
                    disabled={modalLoading}
                    color="primary"
                    variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

export default AlertSubscriptionsForm;