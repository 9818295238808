import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import BuildingEditMenu from '../../containers/BuildingEditMenu/buildingEditMenu';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import LocationsTable from '../../containers/LocationsTable/LocationsTable';
import { createStructuredSelector } from 'reselect';
import { makeSelectBuildingsList, makeSelectBuildingsListLoading, makeSelectBuildingsPageCount, types } from '../../reducers/locations';
import { actions as AppActions, selectHorizonPrimeEnabled } from '../../reducers/app';
import { selectUserRole, originalCompanyOrgType } from '../../reducers/auth';
import { connect } from 'react-redux';
import DetailsModalWrapper from '../../components/DetailsModalWrapper/DetailsModalWrapper';
import LocationsEditMenu from '../../containers/LocationsEditMenu/LocationsEditMenu';

function BuildingsTable(props) {
    const {
        buildings,
        buildingsPageCount,
        searchTerm,
        fetchBuildings,
        setCurrentModal,
        loading,
        isSearchData,
        expandedList,
        userRole,
        setExpandedList,
        horizonPrimeEnabled
    } = props;

    function getGeoCoords(row) {
        if ((row.original.geoCoords) && (row.original.geoCoords.latitude) && (row.original.geoCoords.longitude)) {
            return <div title={`${row.original.geoCoords.latitude}, ${row.original.geoCoords.longitude}`}>{`${row.original.geoCoords.latitude}, ${row.original.geoCoords.longitude}`}</div>;
        } else if (row.original.address && row.original.address.latitude && row.original.address.longitude) {
            return <div title={`${row.original.address.latitude}, ${row.original.address.longitude}`}>{`${row.original.address.latitude}, ${row.original.address.longitude}`}</div>;   
        } else {
            return <div></div>;
        }
    }

    const columns = [
        {
            Header: <div id="buildings-name">Building Name</div>,
            id: "name",
            sortable: true,
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        },
        {
            Header: <div id="buildings-address">Address</div>,
            id: "address",
            Cell: row => <div title={row.original.address ? row.original.address.normalizedAddress : ''}>{row.original.address ? row.original.address.normalizedAddress : ''}</div>
        },
        {
            Header: <div id="buildings-coordinates">Geodetic Coordinates</div>,
            id: "coordinates",
            Cell: row => getGeoCoords(row)
        },
        {
            Header: <div id="buildings-details-box">Details</div>,
            width:90,
            id: "details",
            Cell: row => <DetailsModalWrapper type={types.BUILDING_DETAILS} row={row} userRole={userRole.get("name")}/>
        },
    ];
    
    if (props.tabsValue === 'MANAGER_DATA_SYNC') { 
        columns.push({
            Header: '',
            width: 65,
            id: "edit-menu",
            Cell: row => <LocationsEditMenu row={row}/>
        });
    }
    else {
        columns.push({
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                <BuildingEditMenu row={row} setCurrentModal={setCurrentModal} />
            )
        });
    }

    if (props.originalCompanyOrgType === 'SYSTEM') {
        const statusColumn = {
            Header: <div id="buildings-status">Status</div>,
            id: "status",
            Cell: row => <div title={row.original.address ? row.original.address.msagValid : ''}>{row.original.address ? row.original.address.msagValid : ''}</div>
        };
        const statusIndex = columns.findIndex(item => item.id === 'details');
        
        columns.splice(statusIndex, 0, statusColumn);

        const backendProviderColumn = {
            Header: <div id="backend-provider">Backend Provider</div>,
            id: "backend-provider",
            Cell: row => <div title={row.original.address ? row.original.address.backendProvider : ''}>{
                (row.original.address && row.original.address.backendProvider) ? row.original.address.backendProvider : ''
                }</div>
        };
        
        columns.splice(statusIndex, 0, backendProviderColumn);
    } else {
        if (horizonPrimeEnabled) {
            const backendProviderColumn = {
                Header: <div id="backend-provider">Backend Provider</div>,
                id: "backend-provider",
                Cell: row => <div title={row.original.address && row.original.address.backendProvider === 'HorizonPrime' ? 'Horizon Prime' : 'Standard'}>{
                    row.original.address && row.original.address.backendProvider === 'HorizonPrime' ? 'Horizon Prime' : 'Standard'
                    }</div>
            };
            const statusIndex = columns.findIndex(item => item.id === 'details');
            columns.splice(statusIndex, 0, backendProviderColumn);
        }
    }

    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        setFilteredList([]);
        setExpandedList([]);
    } , [buildings])

    useEffect( () => {
        props.setCurrentPage(1);
    }, []);

    useEffect( () => {
        fetchBuildings(props.page+1, props.pageSize, props.searchValue, props.currentSortColumn.id, props.currentSortColumn.desc ? 'DESC': 'ASC', props.tabsValue === 'MANAGER_DATA_SYNC' ? 'MANAGER_DATA_SYNC' : 'default');
        props.setTableProps({
            page: props.page+1, 
            pageSize: props.pageSize, 
            sortBy: props.currentSortColumn.id, 
            direction: props.currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [props.currentSortColumn, props.pageSize, props.page, props.tabsValue]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }  

    const buildingsArray = buildings ? buildings.toArray() : [];

    useEffect(() => {
        if (buildingsArray && buildingsArray.length === 0 && props.page > 0 && buildingsPageCount < props.page+1) {
            props.setPage(props.page-1)   
        }
    }, [buildingsArray && buildingsArray.length === 0 && props.page > 0 && buildingsPageCount < props.page+1]);
 
    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight"
            showPaginationBottom={true}
            data={filteredList.length > 0 ? filteredList : buildingsArray}
            minRows={buildingsArray && buildingsArray.length > 0 ? 0 : 5}
            pages={buildingsPageCount <= 0 ? 1 : buildingsPageCount}
            page={props.page}
            columns={columns}
            LoadingComponent={ReactTableLoadingComponent}
            manual
            defaultPageSize={25}
            onPageSizeChange={(pageSize) => {
                props.setPageSize(pageSize);
                props.setPage(0);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                props.setCurrentPage(pageIndex + 1);
                props.setPage(pageIndex);
                props.setExpandBuildingsToggle(true);
                resetScrollInsideTable();
            }}
            sorted={props.sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...props.sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    props.setCurrentSortColumn(currentSortColumn);
                    props.setSortColumns(sortedArray);
                })
            }}
            loading={loading}
            expanded = {expandedList}
            onExpandedChange ={(newExpanded, index, event) => {
                setExpandedList(newExpanded);
            }}
            getTrProps = {(state, rowInfo) => {
                if (rowInfo) {
                    rowInfo.rowId = 'buildings-row-' + rowInfo.original.id;

                    return {
                        id: rowInfo.rowId,
                        tabIndex: 0,
                        onKeyDown: e => {
                            if ((e.key === ' ' || e.key === 'Enter') && document.activeElement.tagName !== 'BUTTON') {
                                e.preventDefault();

                                const expandElm = document.querySelectorAll('.rt-expandable')[rowInfo.index];

                                if (expandElm) {
                                    expandElm.click();
                                }
                            }
                        },
                        'show-arrow': 1 
                    } // 1 - true
                } else {
                    return {};
                }
            }}
            SubComponent={
                row => {
                    return(
                        <div style={{padding: "20px" }}>
                            <b style={{ display: 'block', marginBottom: 10}}>Locations</b>
                            <LocationsTable 
                                {...props}
                                buildingRow={row}
                                listExpanded={true}
                                organizationUnitId={row.original.id} 
                                setCurrentModal={setCurrentModal}
                                locationsFromSearch={row.original.locations}
                                buildingFlag={true}/>
                        </div>
                    )
                }
            }
        />
    );
}

const mapStateToProps = createStructuredSelector({
    buildings: makeSelectBuildingsList(),
    buildingsPageCount: makeSelectBuildingsPageCount(),
    loading: makeSelectBuildingsListLoading(),
    userRole: selectUserRole(),
    horizonPrimeEnabled: selectHorizonPrimeEnabled(),
    originalCompanyOrgType: originalCompanyOrgType()
});

const mapDispatchToProps = dispatch => {
    return {
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingsTable);