import { axiosInstance } from './api';
/* Cirrus Admin-Service /call-monitoring Controller */
const CER_SERVERS_ENDPOINT = 'admin-service/cer';
const INTEGRATION_CRED_ENDPOINT = 'admin-service/integrationCred'
const MASS_NOTIFICATION_ENDPOINT = 'admin-service/massNotification'
const COMPANY_ENDPOINT = 'admin-service/company';
const SSO_ENDPOINT = 'auth-service/sso/singleSignOn';
export default class OrganizationManagementAPI {
    static addCERServers(cerServerData, companyId) {
        const payload = {
            ipAddress: cerServerData.ipAddress,
            description: cerServerData.description,
            companyId
        };
        return axiosInstance.post(CER_SERVERS_ENDPOINT, payload);
    }
    
    static editCERServers(cerServerData) {
        const payload = {
            ipAddress: cerServerData.ipAddress,
            description: cerServerData.description,
            id: cerServerData.id
        };
        return axiosInstance.put(CER_SERVERS_ENDPOINT, payload);
    }
    
    static getCERServers(companyId) {
        return axiosInstance.get(`${CER_SERVERS_ENDPOINT}/company/${companyId}`);
    }

    static deleteCERServers(id) {
        return axiosInstance.delete(`${CER_SERVERS_ENDPOINT}/${id}`);
    }

    static addInformaCastToken(token, companyId) {
        return axiosInstance.post(`${COMPANY_ENDPOINT}/${companyId}/informacast/${token}`);
    }

    static getIntegrationCred(id, name) {
        return axiosInstance.get(`${INTEGRATION_CRED_ENDPOINT}/${id}/name/${name}`);
    }

    static getIntegrationCredList(id) {
        return axiosInstance.get(`${INTEGRATION_CRED_ENDPOINT}/${id}`);
    }

    static getMassNotificationsTemplates(id) {
        return axiosInstance.get(`${MASS_NOTIFICATION_ENDPOINT}/${id}`);
    }

    static addIntegrationCred(integrationCredArray) {
        return axiosInstance.post(INTEGRATION_CRED_ENDPOINT, integrationCredArray);
    }

    static deleteIntegrationCred(id) {
        return axiosInstance.delete(`${INTEGRATION_CRED_ENDPOINT}/${id}`);
    }

    static addSsoSettings(payload) {
        return axiosInstance.post(SSO_ENDPOINT, payload);
    }
}