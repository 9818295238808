import React, { useState } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import CompanyFeaturesApi from '../../../apis/companyFeaturesApi';
import { createStructuredSelector } from "reselect";
import { selectUserCompany, currentCompanyLevel } from '../../../reducers/auth';
import { actions as OrganizationManagementActions } from '../../../reducers/organizationManagement';
import { types as AppTypes, selectLLDPChassisAutoCreationEnabled, selectLLDPPortAutoCreationEnabled, selectCorpBSSIDAutoCreationEnabled } from '../../../reducers/app';
import { connect } from "react-redux";
import Switch from '@material-ui/core/Switch';

import Grid from '@material-ui/core/Grid';

function NetworkDataAutoCreationFeature(props) {
    
    const [submitStatus, setSubmitStatus] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const company = props.company.toJS();
   
    const handleLLDPChassisAutoCreationEnabledChange = () => {
        setDataLoading(true);
        (props.lldpChassisAutoCreationEnabled ? 
            CompanyFeaturesApi.removeCompanyFeatures(company.id, 'LLDP_CHASSIS_AUTO_CREATION') : 
            CompanyFeaturesApi.addCompanyFeatures(company.id, 'LLDP_CHASSIS_AUTO_CREATION')
        )
        .then(response => {
            setSubmitStatus('success');
            setDataLoading(false);
            props.getOrganizationFeatures();
        }).catch(error => {
            console.log(error)
            setSubmitStatus('failure');
            setDataLoading(false);
        });
    }

    const handleLLDPPortAutoCreationEnabledChange = () => {
        setDataLoading(true);
        (props.lldpPortAutoCreationEnabled ?
            CompanyFeaturesApi.removeCompanyFeatures(company.id, 'LLDP_PORT_AUTO_CREATION') : 
            CompanyFeaturesApi.addCompanyFeatures(company.id, 'LLDP_PORT_AUTO_CREATION')
        )
        .then(response => {
            setSubmitStatus('success');
            setDataLoading(false);
            props.getOrganizationFeatures();
        }).catch(error => {
            console.log(error)
            setSubmitStatus('failure');
            setDataLoading(false);
        });
    }

    const handleCorpBSSIDAutoCreationEnabledChange = () => {
        setDataLoading(true);
        (props.corpBSSIDAutoCreationEnabled ?
            CompanyFeaturesApi.removeCompanyFeatures(company.id, 'CORP_BSSID_AUTO_CREATION') : 
            CompanyFeaturesApi.addCompanyFeatures(company.id, 'CORP_BSSID_AUTO_CREATION')
        )
        .then(response => {
            setSubmitStatus('success');
            setDataLoading(false);
            props.getOrganizationFeatures();
        }).catch(error => {
            console.log(error)
            setSubmitStatus('failure');
            setDataLoading(false);
        });
    }

    return (
         <div>
            {dataLoading && <LinearProgress/>}
            <Grid container justify="center" direction="column" alignItems="center" style={{margin: '20px'}}>
                {dataLoading && <LinearProgress/>}
                <Grid container justify="center" direction="row">
                    <Grid item xs={12} sm={3}>
                        <label htmlFor="CorpBSSIDAutoCreationSwitch">Allow Automatic Addition of Corporate BSSID Network Elements</label>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Switch
                            id="CorpBSSIDAutoCreationSwitch"
                            name="CorpBSSIDAutoCreationSwitch"
                            color="primary"
                            checked={props.corpBSSIDAutoCreationEnabled}
                            onChange={handleCorpBSSIDAutoCreationEnabledChange}
                            disabled={dataLoading || !props.disableInput}/>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container justify="center" direction="row">
                    <Grid item xs={12} sm={3}>
                        <label htmlFor="LLDPChassisAutoCreationSwitch">Allow Automatic Addition of Corporate LLDP Chassis Network Elements</label>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Switch
                            id="LLDPChassisAutoCreationSwitch"
                            name="LLDPChassisAutoCreationSwitch"
                            color="primary"
                            checked={props.lldpChassisAutoCreationEnabled}
                            onChange={handleLLDPChassisAutoCreationEnabledChange}
                            disabled={dataLoading || !props.disableInput}/>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container justify="center" direction="row">
                    <Grid item xs={12} sm={3}>
                        <label htmlFor="LLDPPortAutoCreationSwitch">Allow Automatic Addition of Corporate LLDP Port Network Elements</label>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Switch
                            id="LLDPPortAutoCreationSwitch"
                            name="LLDPPortAutoCreationSwitch"
                            color="primary"
                            checked={props.lldpPortAutoCreationEnabled}
                            onChange={handleLLDPPortAutoCreationEnabledChange}
                            disabled={dataLoading || !props.disableInput}/>
                    </Grid>
                </Grid>
            </Grid>
            {submitStatus === 'failure' && 
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 680 }}>
                <ErrorOutlineIcon style={{ color: '#f4473a', marginRight: 10, fontSize: 30 }}/>
                <div style={{ color: '#f4473a' }}>ERROR! We are not able to save your preference at this time. Please try again later.</div>
            </div>
            }
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    company: selectUserCompany(),
    lldpChassisAutoCreationEnabled: selectLLDPChassisAutoCreationEnabled(),
    lldpPortAutoCreationEnabled: selectLLDPPortAutoCreationEnabled(),
    corpBSSIDAutoCreationEnabled: selectCorpBSSIDAutoCreationEnabled()


});

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationFeatures: () => dispatch(OrganizationManagementActions.getOrganizationFeatures())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NetworkDataAutoCreationFeature);